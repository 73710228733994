import C from "./Dashboard.module.css";

import type React from "react";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { cn } from "../../utils/classNames.ts";

export function NextArrow(props: {
    className?: string | undefined;
    onClick?: React.MouseEventHandler<any> | undefined;
}) {
    const { className, onClick } = props;
    return (
        <div className={cn(className, C.NextArrow)}>
            <button type="button" className="jp-mod-accept-color" onClick={onClick}>
                <MaskIcon className="jp-arrow-thin-right-icon size-0.75rem" />
            </button>
        </div>
    );
}
